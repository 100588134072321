var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page inner-container collection"},[_c('section',{staticClass:"collection--row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("collection.collection_title")))])]),_c('section',{staticClass:"collection--row collection--list"},[_c('div',{staticClass:"row"},_vm._l((_vm.collectionList),function(collection){return _c('div',{key:collection.id,staticClass:"col-6 col-md-6 col-xl-4"},[_c('router-link',{attrs:{"to":{
              name: 'Collection Details',
              params: { slug: collection.slug }
            }}},[_c('CollectionItem',{attrs:{"fullWidthCheck":true,"name":_vm.lang == 'en' ? collection.title_en : collection.title_tc,"description":_vm.lang == 'en'
                  ? collection.description_en
                  : collection.description_tc,"imageName":collection.records.length > 0
                  ? collection.records[0].gallery[0].image
                  : 'media/hs-00003/hs-00003.jpg'}})],1)],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }