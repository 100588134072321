<template>
  <div>
    <div class="page inner-container collection">
      <section class="collection--row">
        <h2>{{ $t("collection.collection_title") }}</h2>
      </section>
      <section class="collection--row collection--list">
        <div class="row">
          <div
            class="col-6 col-md-6 col-xl-4"
            v-for="collection in collectionList"
            :key="collection.id"
          >
            <router-link
              :to="{
                name: 'Collection Details',
                params: { slug: collection.slug }
              }"
            >
              <CollectionItem
                :fullWidthCheck="true"
                :name="lang == 'en' ? collection.title_en : collection.title_tc"
                :description="
                  lang == 'en'
                    ? collection.description_en
                    : collection.description_tc
                "
                :imageName="
                  collection.records.length > 0
                    ? collection.records[0].gallery[0].image
                    : 'media/hs-00003/hs-00003.jpg'
                "
              ></CollectionItem>
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import CollectionItem from "@/components/masonry/CollectionItem";
export default {
  name: "CollectionIndex",
  components: { CollectionItem },
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      collectionList: [],
      singleCollectionJson: {
        title_en: "I Club, Visual Identity System",
        title_tc: "中文",
        imageUrl: "media/hs-00001/hs-00001.jpg",
        sizeType: "portrait"
      }
    };
  },
  beforeMount() {
    this.apiCollectionItem();
  },

  methods: {
    async apiCollectionItem() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/collection`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }

        const data = await response.json();

        this.collectionList = data.filter(collection => collection.status == 1);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.collection {
  h2 {
    @include typo(bigTit);
    margin-bottom: 15px;
    @include respond-to(screen-sm) {
      margin-bottom: 20px;
    }
  }
  &--row {
    margin-bottom: 40px;
    @include respond-to(screen-sm) {
      margin-bottom: 80px;
    }
  }
  &--info {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    @include respond-to(screen-sm) {
      display: flex;
    }
    &--item {
      margin-bottom: 20px;
      @include respond-to(screen-sm) {
        margin-right: 120px;
        margin-bottom: 30px;
      }
    }
    span {
      margin-bottom: 5px;
      display: block;
    }
    h4 {
      font-size: 18px;
      font-weight: 400;
      @include respond-to(screen-sm) {
        font-size: 20px;
      }
    }
  }
  .ft-item {
    border: 1px solid $color-black;
    position: relative;
    overflow: hidden;
    padding-top: 67%;
    &--wrap {
      // padding: 100px;
    }
    &--inItem {
      @include center(xy);
      font-size: 22px;
      .plus {
        margin: auto;
        width: 25px;
        height: 2px;
        background: black;
        // transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        // border-radius: 2px;
        &:after {
          content: "";
          position: absolute;
          width: 25px;
          height: 2px;
          background: black;
          border-radius: 2px;
          transform: rotate(90deg);
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        }
      }
      p {
        margin-top: 20px;
      }
    }
  }
  // .edit {
  //   @include transition(all 300ms ease-in-out);
  //   text-decoration: underline;
  //   font-size: 16px;
  //   &:hover {
  //     opacity: 0.7;
  //   }
  // }
}
</style>
